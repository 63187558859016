
import { defineComponent, ref } from 'vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

export default defineComponent({
  name: 'Notes',

  components: {
    DashboardLayout,
  },

  setup() {
    const columns = ref([{
      prop: 'name',
      label: 'Name',
    }, {
      prop: 'email',
      label: 'Email',
    }, {
      prop: 'role',
      label: 'Role',
    }, {
      prop: 'status',
      label: 'Status',
    }]);

    return {
      columns,
    };
  },
});

